import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { RankingWinnersComponent, RankingComponent } from './components/index';

// Angular Material
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [RankingWinnersComponent, RankingComponent],
  imports: [CommonModule, MatInputModule, MatListModule, FormsModule, ReactiveFormsModule, CountdownModule, TranslateModule],
  exports: [
    MatInputModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownModule,
    TranslateModule,
    RankingWinnersComponent,
    RankingComponent,
  ],
})
export class SharedModule {}
