import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, distinctUntilChanged, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { Languages } from '../languages/languages.component';
import { ContextService } from '@shared/services/context.service';

const BASE_URL = 'https://malariaspot.org/';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _lang$ = new BehaviorSubject<string | typeof Languages>(this._translateService.currentLang || Languages.ES);
  get lang$() {
    return this._lang$.asObservable();
  }

  public readonly isTablet$ = this._contextService.showTabletLayout$;

  public readonly langs: typeof Languages = Languages;
  private _routeValue$ = new Subject<string>();
  private routeValue$ = this._routeValue$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  constructor(private _translateService: TranslateService, private _contextService: ContextService) {}

  ngOnInit(): void {
    this._translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((res) => this._lang$.next(res.lang));
    this.routeValue$
      .pipe(
        takeUntil(this._destroy$),
        switchMap((_route) => this._translateService.get(_route)),
        tap((_translation) => {
          const finalUrl = this._lang$.value === this.langs.ES ? `${_translation}` : `${this._lang$.value}/${_translation}`;
          window.location.href = `${BASE_URL}${finalUrl}`;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public redirect(langKey: string) {
    this._routeValue$.next(langKey);
  }
}
