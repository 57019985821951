import { Component, OnInit, Input } from '@angular/core';
import { Marcadores } from '@parse/index';
import { Context, ContextService } from '@shared/services/context.service';

@Component({
  selector: 'app-ranking-winners',
  templateUrl: './ranking-winners.component.html',
  styleUrls: ['./ranking-winners.component.scss'],
})
export class RankingWinnersComponent implements OnInit {
  private _ranking: Marcadores[] = [];

  get ranking(): Marcadores[] {
    return this._ranking;
  }

  @Input()
  set ranking(val: Marcadores[]) {
    this._ranking = val?.filter((v, i) => i < 3);
  }

  public readonly context: typeof Context = Context;

  public readonly currentContext$ = this._contextService.currentContext$;
  public readonly isMobile$ = this._contextService.showMobileLayout$;

  constructor(private _contextService: ContextService) {}

  ngOnInit(): void {}
}
