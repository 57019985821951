import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../layout/index';

const langs = Languages;

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver implements Resolve<Languages> {
  constructor(private _translate: TranslateService) {}
  resolve(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ): Observable<Languages> {
    const lang = (route.params?.lang || langs.ES) as Languages;
    this._translate.use(lang);
    return of(lang);
  }
}
