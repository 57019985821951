import { Component, OnInit } from '@angular/core';

export enum Languages {
  ES = 'es',
  EN = 'en',
  PT = 'pt-pt',
}

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  public readonly lang: typeof Languages = Languages;

  constructor() {}

  ngOnInit(): void {}
}
