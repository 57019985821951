import { Component, OnInit, Input } from '@angular/core';
import { Marcadores } from '@parse/index';
import { ContextService, Context } from '@shared/services/context.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {
  private _ranking: Marcadores[];
  private _isMobile: boolean = false;

  get ranking(): Marcadores[] {
    return this._ranking;
  }

  @Input()
  set ranking(val: Marcadores[]) {
    this._ranking = val;
  }

  public readonly context: typeof Context = Context;

  public readonly currentContext$ = this._contextService.currentContext$;
  public readonly isMobile$ = this._contextService.showMobileLayout$.pipe(tap((_isMobile) => (this._isMobile = _isMobile)));

  constructor(private _contextService: ContextService) {}

  ngOnInit(): void {}

  public getRanking(): Marcadores[] {
    return this._isMobile ? this._ranking : this._ranking?.filter((v, i) => i > 2);
  }
}
