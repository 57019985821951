import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ContextService, Context } from '@shared/services/context.service';

@Injectable({
  providedIn: 'root',
})
export class ContextResolver implements Resolve<string> {
  constructor(private _context: ContextService) {}
  resolve(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ): Observable<string> {
    const path = route.url[0]?.path;
    switch (path) {
      case 'competition':
        this._context.changeContext(Context.createCompetition);
        break;
      case 'ranking':
        this._context.changeContext(Context.individualRanking);
        break;
      default:
        this._context.changeContext(Context.createCompetition);
    }
    return of(path);
  }
}
