<ng-container *ngIf="{ lang: lang$ | async, device: isTablet$ | async } as data">
  <ng-container *ngIf="!data.device; else tablet">
    <div class="flex-row justify-evenly align-center">
      <button type="button" class="flex-row justify-center align-center button __empty">
        <a [href]="data.lang === langs.ES
                    ? 'https://malariaspot.org/'
                    : 'https://malariaspot.org/' + data.lang">{{ 'menu.home' | translate | uppercase }}</a>
      </button>
      <button type="button" class="flex-row justify-center align-center button __empty">
        <a (click)="redirect('url.collaborate')">{{ 'menu.collaborate' | translate | uppercase }}</a>
      </button>
      <button type="button" class="flex-row justify-center align-center button __empty">
        <a [href]="
                  data.lang === langs.ES
                    ? 'https://malariaspot.org/eduspot/'
                    : 'https://malariaspot.org/' + data.lang + '/eduspot'
                ">{{ 'Eduspot' | translate | uppercase }}</a>
      </button>
      <button type="button" class="flex-row justify-center align-center button __empty">
        <a (click)="redirect('url.about-us')">{{ 'menu.us' | translate | uppercase }}</a>
      </button>
      <button type="button" class="flex-row justify-center align-center button __fill __play">
        <a href="https://game.malariaspot.org/">{{ 'menu.play' | translate | uppercase }}</a>
      </button>
    </div>
  </ng-container>
  <ng-template #tablet>
    <mat-list-item>
      <a [href]="data.lang === langs.ES
                    ? 'https://malariaspot.org/'
                    : 'https://malariaspot.org/' + data.lang">{{ 'menu.home' | translate }}</a>
      <mat-divider></mat-divider>
      <a (click)="redirect('url.collaborate')">{{ 'menu.collaborate' | translate }}</a>
      <mat-divider></mat-divider>
      <a [href]="
                data.lang === langs.ES
                  ? 'https://malariaspot.org/eduspot/'
                  : 'https://malariaspot.org/' + data.lang + '/eduspot'
              ">{{ 'Eduspot' | translate }}</a>
      <mat-divider></mat-divider>
      <a (click)="redirect('url.about-us')">{{ 'menu.us' | translate }}</a>
      <mat-divider></mat-divider>
      <a href="https://game.malariaspot.org/">{{ 'menu.play' | translate }}</a>
    </mat-list-item>
  </ng-template>
</ng-container>
