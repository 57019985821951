import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layout/index';
import { ContextResolver } from '@app/index';

const routes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: 'competition',
        loadChildren: () => import('@modules/competition/competition.module').then((m) => m.CompetitionModule),
        resolve: { route: ContextResolver },
      },
      {
        path: 'ranking',
        loadChildren: () => import('@modules/ranking/ranking.module').then((m) => m.RankingModule),
        resolve: { route: ContextResolver },
      },
      {
        path: '',
        redirectTo: 'competition',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'competition',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
