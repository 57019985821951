import { Component, OnInit } from '@angular/core';
import { ContextService } from '@shared/services/context.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public get currentYear() {
    return new Date().getFullYear();
  }

  public readonly isMobile$ = this._contextService.showMobileLayout$;

  constructor(private _contextService: ContextService) {}

  ngOnInit(): void {}
}
