import * as models from './api';

/* Models */
export * from './api';

export { Query, Object as ParseObject } from 'parse';

export const Parse = require('parse');

export function configure({
  serverURL,
  appId,
  javascriptKey,
  masterKey,
}: {
  serverURL: string;
  appId: string;
  javascriptKey: string;
  masterKey?: string;
}) {
  Object.keys(models).forEach((k) => Parse.Object.registerSubclass(models[k].className, models[k]));
  Parse.initialize(appId, javascriptKey, masterKey);
  Parse.serverURL = serverURL;
}
