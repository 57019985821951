<div class="container-app">
  <div class="container-main full-height flex-column justify-between align-center">
    <div *ngIf="!(isTablet$ | async); else tablet" class="flex-column align-center" style="width: 100%">
      <div class="flex-row justify-between container-header">
        <span style="visibility: hidden"><app-languages></app-languages></span>
        <img style="margin-top: 35px" src="../../../assets/logo.png" />
        <app-languages class="langs"></app-languages>
      </div>
      <div class="menu"><app-menu></app-menu></div>
    </div>
    <ng-template #tablet>
      <app-languages class="langs"></app-languages>
      <div class="flex-row justify-between align-center" style="width: 100%">
        <span (click)="toggleNav()" style="margin-left: 5%">
          <i style="font-size: 24px" class="ri-menu-line"></i>
        </span>
        <img src="../../../assets/logo.png" />
        <span style="visibility: hidden">
          <i style="font-size: 24px" class="ri-menu-line"></i>
        </span>
      </div>
      <div style="width: 90%"><app-menu *ngIf="showMenu"></app-menu></div>
    </ng-template>
    <div class="container-router"><router-outlet></router-outlet></div>
    <div class="full-width footer"><app-footer></app-footer></div>
  </div>
</div>
