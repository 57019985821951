import { NgModule, Inject, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { configure } from './sdk';
import { DataService } from './services/data.service';

export function DataServiceFactory() {
  let verbose = false;
  try {
    verbose = JSON.parse(localStorage.getItem('verbose') || 'false');
  } catch (err) {}
  return new DataService(verbose);
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ParseModule {
  constructor(@Inject('environment') environment) {
    configure({
      serverURL: `${environment.api.url}`,
      appId: environment.api.appId,
      javascriptKey: environment.api.jsKey,
    });
  }

  static forRoot(config?: any): ModuleWithProviders<ParseModule> {
    return {
      ngModule: ParseModule,
      providers: [
        { provide: 'environment', useValue: config.environment },
        {
          provide: DataService,
          useFactory: DataServiceFactory,
        },
      ],
    };
  }
}
