<ng-container *ngIf="{ cc: currentContext$ | async, isMobile: isMobile$ | async } as data">
  <div class="container-main">
    <ng-container *ngIf="ranking">
      <div class="flex-row align-center justify-center" [class.container-header]="!data.isMobile">
        <h5 class="flex-row justify-start column header">{{ 'label.name' | translate }}</h5>
        <h5
          class="flex-row justify-center column header"
          [class.justify-end]="!(data.cc === context.individualRanking)"
          [class.adjust]="data.cc === context.individualRanking && !data.isMobile"
        >
          {{ 'label.score' | translate }}
        </h5>
        <h5 *ngIf="data.cc === context.individualRanking" class="flex-row justify-end column header">
          {{ 'label.team' | translate }}
        </h5>
      </div>
      <mat-divider></mat-divider>
      <mat-list-item *ngFor="let rank of getRanking(); let index = index">
        <div class="flex-row align-center" [class.container-row]="!data.isMobile">
          <div *ngIf="!data.isMobile; else mobile" class="flex-row align-center justify-start column name">
            <h2 style="padding-right: 24px"><span *ngIf="index < 6" style="visibility: hidden">0</span>{{ index + 4 }}</h2>
            <p style="font-weight: bold">{{ rank?.name }}</p>
          </div>
          <ng-template #mobile>
            <div class="flex-row align-center justify-start column name">
              <p style="padding-right: 8px; font-weight: bold">
                <span *ngIf="index < 9" style="visibility: hidden">0</span>{{ index + 1 }}
              </p>
              <p style="font-weight: bold">{{ rank?.name }}</p>
            </div>
          </ng-template>
          <h4
            class="flex-row align-center justify-center column score"
            [class.justify-end]="!(data.cc === context.individualRanking)"
          >
            {{ rank?.score }} pt
          </h4>
          <p class="team" *ngIf="data.cc === context.individualRanking" class="flex-row align-center justify-end column">
            {{ rank?.score_code }}
          </p>
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </ng-container>
  </div>
</ng-container>
