import { Component, OnInit } from '@angular/core';
import { ContextService } from '@shared/services/context.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
  public readonly isTablet$ = this._contextService.showTabletLayout$;
  showMenu = false;

  constructor(private _contextService: ContextService) {}

  ngOnInit(): void {}

  public toggleNav() {
    this.showMenu = !this.showMenu;
  }
}
