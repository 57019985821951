<ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
  <div class="container align-center justify-evenly" [class.flex-row]="!data.isMobile" [class.flex-column]="data.isMobile">
    <p>info@spotwarriors.org</p>
    <div class="flex-row align-center justify-center">
      <a href="https://www.facebook.com/MalariaSpot.SpotLab/?ref=bookmarks" target="_blank" style="margin-right: 15px"
        ><i class="ri-facebook-fill"></i
      ></a>
      <a href="https://twitter.com/malariaspot" target="_blank"><i class="ri-twitter-fill"></i></a>
    </div>
    <a href="https://spotlab.org" target="_blank">© Spotlab, S.L. {{ currentYear }}</a>
  </div>
</ng-container>
