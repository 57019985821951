import { ParseObject, Query } from '../sdk';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class DataService {
  constructor(@Inject('_verbose') private _verbose: boolean = false) {}

  async first<T extends ParseObject>(query: Query<T>): Promise<T> {
    try {
      const result = await query.first();
      if (this._verbose) {
        if (result !== undefined) {
          console.log(`%c first ${query.className} (${result.id}) retrieved:`, 'color: purple', result);
        } else {
          console.log(`%c No ${query.className} retrieved:`, 'color: purple', result);
        }
      }
      return Promise.resolve(result);
    } catch (error) {
      if (this._verbose) {
        console.error(`%c first ${query.className} failed: ${error.message}`, 'color: red');
      }
      throw error;
    }
  }

  async get<T extends ParseObject>(id: string, query: Query<T>): Promise<T> {
    try {
      const result = await query.get(id);
      if (result !== undefined) {
        if (this._verbose) {
          console.log(`%c ${query.className} (${result.id}) retrieved:`, 'color: purple', result);
        }
      } else {
        throw new Error(`item with ID ${id} not found.`);
      }
      return result;
    } catch (error) {
      if (this._verbose) {
        console.error(`%c get ${query.className} failed: ${error.message}`, 'color: red');
      }
      throw error;
    }
  }

  async find<T extends ParseObject>(query: Query<T>): Promise<T[]> {
    try {
      const results = await query.find();
      if (this._verbose) {
        console.log(`%c ${results.length} ${query.className}(s) retrieved:`, 'color: purple', results);
      }
      return results;
    } catch (error) {
      if (this._verbose) {
        console.error(`%c find ${query.className} failed: ${error.message}`, 'color: red');
      }
      throw error;
    }
  }

  async count<T extends ParseObject>(query: Query<T>): Promise<number> {
    try {
      const results = await query.count();
      if (this._verbose) {
        console.log(`%c ${results} ${query.className}(s) results available:`, 'color: purple', results);
      }
      return results;
    } catch (error) {
      if (this._verbose) {
        console.error(`%c find ${query.className} failed: ${error.message}`, 'color: red');
      }
      throw error;
    }
  }

  async save<T extends ParseObject>(object: T | T[]): Promise<T | T[]> {
    try {
      const results = object instanceof Array ? await ParseObject.saveAll(object) : await object.save();

      if (this._verbose) {
        if (results instanceof Array && results.length > 0) {
          console.log(`%c ${results.length} ${results[0].className}'(s) saved:`, 'color: green', results);
        } else {
          console.log(`%c ${results['className']} (${results['id']}) saved:`, 'color: green', results);
        }
      }

      return results;
    } catch (error) {
      if (this._verbose) {
        if (object instanceof Array) {
          console.error(`%c save ${object.map((o) => o.id)} failed: ${error.message}`, 'color: red');
        } else {
          console.error(`%c save ${object.id} failed: ${error.message}`, 'color: red');
        }
      }

      throw error;
    }
  }

  async delete<T extends ParseObject>(object: T | T[]): Promise<T | T[]> {
    try {
      const results = object instanceof Array ? await ParseObject.destroyAll(object) : await object.destroy();

      if (this._verbose) {
        if (results instanceof Array && results.length > 0) {
          console.log(`%c ${results.length} ${results[0].id}'(s) deleted:`, 'color: green', results);
        } else {
          console.log(`%c ${results['className']} (${results['id']}) deleted:`, 'color: green', results);
        }
      }

      return results;
    } catch (error) {
      if (this._verbose) {
        if (object instanceof Array) {
          console.error(`%c delete ${object.map((o) => o.id)} failed: ${error.message}`, 'color: red');
        } else {
          console.error(`%c delete ${object.id} failed: ${error.message}`, 'color: red');
        }
      }

      throw error;
    }
  }
}
