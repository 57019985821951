import { Object as ParseObject } from 'parse';

export class Marcadores extends ParseObject {
  static className = 'Marcadores';

  constructor({
    name,
    score,
    score_code,
  }: {
    name?: string;
    score?: string;
    score_code?: string;
  } = {}) {
    super(Marcadores.className);
    this.name = name;
    this.score = score;
    this.score_code = score_code;
  }

  get name(): string {
    return this.get('name');
  }
  set name(value: string) {
    this.set('name', value);
  }

  get score(): string {
    return this.get('score');
  }
  set score(value: string) {
    this.set('score', value);
  }

  get score_code(): string {
    return this.get('score_code');
  }
  set score_code(value: string) {
    this.set('score_code', value);
  }
}
