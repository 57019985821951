import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

export enum Context {
  createCompetition,
  chronoCompetition,
  individualRanking,
  teamRanking,
}

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private _currentContext$ = new BehaviorSubject<Context>(null);
  public readonly currentContext$ = this._currentContext$.asObservable();

  private _competition$ = new BehaviorSubject({});
  public readonly competition$ = this._competition$.asObservable();

  // layouts
  public readonly showTabletLayout$: Observable<boolean> = this._breakpoint
    .observe(['(max-width: 1024px)'])
    .pipe(map((r) => r.matches));

  public readonly showMobileLayout$: Observable<boolean> = this._breakpoint
    .observe(['(max-width: 640px)'])
    .pipe(map((r) => r.matches));

  constructor(private _breakpoint: BreakpointObserver) {}

  public changeContext(context: Context) {
    this._currentContext$.next(context);
  }

  public startCompetition({ name, countdown }: { name: string; countdown: number }) {
    this._competition$.next({ name, countdown });
    this.changeContext(Context.chronoCompetition);
  }
}
