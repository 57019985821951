<ng-container *ngIf="!(isMobile$ | async)">
  <div class="flex-row justify-center align-center">
    <div class="position-container flex-column align-center justify-center">
      <div style="height: 112px; width: 112px; margin-bottom: 24px">
        <img src="../../../../assets/ranking/2.png" />
      </div>
      <h6 class="name-second">{{ ranking && ranking[1]?.name }}</h6>
      <h5 class="score-second">{{ ranking && ranking[1]?.score }} pt</h5>
      <h6 class="team-second">{{ ranking && ranking[1]?.score_code }}</h6>
    </div>
    <div class="position-container flex-column align-center justify-center __winner">
      <div style="height: 136px; width: 136px; margin-bottom: 24px">
        <img src="../../../../assets/ranking/1.png" />
      </div>
      <h6 class="name-first">{{ ranking && ranking[0]?.name }}</h6>
      <h5 class="score-first">{{ ranking && ranking[0]?.score }} pt</h5>
      <h6 class="team-first">{{ ranking && ranking[0]?.score_code }}</h6>
    </div>
    <div class="position-container flex-column align-center justify-center">
      <div style="height: 112px; width: 112px; margin-bottom: 24px">
        <img src="../../../../assets/ranking/3.png" />
      </div>
      <h6 class="name-third">{{ ranking && ranking[2]?.name }}</h6>
      <h5 class="score-third">{{ ranking && ranking[2]?.score }} pt</h5>
      <h6 class="team-third">{{ ranking && ranking[2]?.score_code }}</h6>
    </div>
  </div>
</ng-container>
